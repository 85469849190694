<template>
   <div>
      <el-row class="search_row">

         <el-col :span="12">
            <div>
               <el-input
                  style="width:20%;float:left;margin-left:10px;margin-top:10px"
                  v-model="search.SalesCode"
                  clearable
                  placeholder="销售编号："
               />
               <el-input
                  style="width:20%;float:left;margin-left:10px;margin-top:10px"
                  v-model="search.SalesName"
                  clearable
                  placeholder="销售名字："
               />
               <el-input
                  style="width:20%;float:left;margin-left:10px;margin-top:10px"
                  v-model="search.SalesMobile"
                  clearable
                  placeholder="销售手机："
               />

               <el-button
                  style="float:left;margin-left:10px;margin-top:10px"
                  type="success"
                  plain
                  @click="getQrList"
               >搜索</el-button>
               <el-button
                  style="float:left;margin-left:10px;margin-top:10px"
                  type="primary"
                  @click="downloadQr"
                  plain
               >导出搜索结果</el-button>
            </div>
         </el-col>
      </el-row>
      <el-row class="search_row">
         <el-col :span="6">
            <span style="height:50px;margin-left:10px;line-height:50px;float:left">
               单独生成:
            </span>
            <el-button
               style="float:left;margin-left:10px;margin-top:10px"
               type="primary"
               plain
               @click="dialogVisible = true"
            >生成二维码</el-button>
         </el-col>
         <el-col :span="6">
            <span style="float:left;margin-left:10px;height:50px;line-height:50px">
               excel导入生成:
            </span>
            <el-upload
               :on-error="uploadError"
               :on-success="uploadSuccess"
               :limit="1"
               style="float:left;margin-left:10px;margin-top:10px"
               action="https://mxrtrain.com/uploadapi/BBRQRCodeOpService/upload"
            >
               <template #trigger>
                  <el-button
                     style="float:left;"
                     type="primary"
                  >选择excel并生成</el-button>
               </template>
            </el-upload>
         </el-col>
      </el-row>
      <el-row>
         <el-table
            :data="tableData"
            style="width: 100%;height:600px"
         >
            <el-table-column
               prop="SalesCode"
               label="销售编号"
               width="180"
            />
            <el-table-column
               prop="SalesName"
               label="销售姓名"
               width="180"
            />
            <el-table-column
               prop="SalesMobile"
               label="销售手机"
               width="180"
            />
            <el-table-column
               label="二维码号"
               width="180"
               
               prop="MemberCode"
            >
            </el-table-column>
            <el-table-column label="二维码图片">
               <template #default="scope">
                  <el-image
                     style="width: 50px; height: 50px"
                     :src="this.apiUrl+scope.row.QRCodeFile"
                     :preview-src-list="[this.apiUrl+scope.row.QRCodeFile]"
                     :initial-index="4"
                     fit="cover"
                     preview-teleported
                  />

               </template>
            </el-table-column>
         </el-table>
         <el-dialog
            v-model="dialogVisible"
            title="生成二维码"
            width="30%"
         >
            <el-row class="row_css">
               <el-col :span="6">
                  <span style="line-height:48px;height:48px">销售编号:</span>
               </el-col>
               <el-col :span="18">
                  <el-input
                     style="width:60%;margin-top:10px;margin-left:10px;float:left"
                     v-model="formData.SalesCode"
                     placeholder="销售编号："
                  />
               </el-col>
            </el-row>
            <el-row class="row_css">
               <el-col :span="6">
                  <span style="line-height:48px;height:48px">销售姓名:</span>
               </el-col>
               <el-col :span="18">
                  <el-input
                     style="width:60%;margin-top:10px;margin-left:10px;float:left"
                     v-model="formData.SalesName"
                     placeholder="销售姓名"
                  />
               </el-col>
            </el-row>
            <el-row class="row_css">
               <el-col :span="6">
                  <span style="line-height:48px;height:48px">销售手机:</span>
               </el-col>
               <el-col :span="18">
                  <el-input
                     style="width:60%;margin-top:10px;margin-left:10px;float:left"
                     v-model="formData.Mobile"
                     placeholder="销售手机"
                  />
               </el-col>
            </el-row>
            <el-row class="row_css">
               <el-col :span="6">
                  <span style="line-height:48px;height:48px">生成数量:</span>
               </el-col>
               <el-col :span="18">
                  <el-input-number
                     :step="1"
                     :min="0"
                     :max="300"
                     style="width:60%;margin-top:10px;margin-left:10px;float:left"
                     v-model="formData.Count"
                     placeholder="销售编号："
                  />
               </el-col>
            </el-row>
            <template #footer>
               <span class="dialog-footer">
                  <el-button @click="dialogVisible = false">取消</el-button>
                  <el-button
                     type="primary"
                     @click="CreateQr"
                  >生成</el-button>
               </span>
            </template>
         </el-dialog>
      </el-row>
   </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { addQrCode, getQrList, downloadQrList } from "@/api/flowApi";
import { ElMessage } from 'element-plus';


// import { ref } from 'vue';

export default {
   name: "QrAdmin",
   data() {
      return {
         formData: {
            SalesCode: null,
            SalesName: null,
            Mobile: null,
            Count: 0,
         },
         dialogVisible: false,
         search: {
            SalesCode: null,
            SalesName: null,
            Mobile: null,
         },
         tableData: [],
         apiUrl: null
      }
   },
   mounted() {
      this.getQrList();

      this.apiUrl = getCurrentInstance()?.appContext.config.globalProperties.$global.apiUrl
   },
   methods: {
      async getQrList() {
         let res = await getQrList({
            Query: {
               SalesCode: this.search.SalesCode ? this.search.SalesCode : null,
               SalesName: this.search.SalesName ? this.search.SalesName : null,
               Mobile: this.search.Mobile ? this.search.Mobile : null,
               OrderBy: [["SalesCode", "asc"],["Code", "asc"]]
            }
         });
         this.tableData = res.data
         console.log("getQrList", res);
      },
      CreateQr() {
         let res = addQrCode({
            Query: this.formData
         });
         if (res.status == 'error') {

            ElMessage.error('添加二维码失败，请重试。原因：' + res.msg
            )
         } else {
            ElMessage({
               message: '添加二维码成功',
               type: 'success',
            })
            this.dialogVisible = false;
            this.getQrList();
         }
      },
      uploadSuccess() {
         ElMessage({
            message: '添加二维码成功',
            type: 'success',
         })
         this.getQrList();
      },
      uploadError() {
         ElMessage.error('批量生成二维码失败，请检查excel内数据格式')

      },
      blobDownload(blob, title) {
         if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, title);
         } else {
            const link = document.createElement("a");
            const body = document.querySelector("body");
            link.href = window.URL.createObjectURL(blob);
            link.download = title;
            // fix Firefox
            link.style.display = "none";
            body.appendChild(link);
            link.click();
            body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
         }
      },

      async downloadQr() {
         let res = await downloadQrList({
            Query: {
               SalesCode: this.search.SalesCode ? this.search.SalesCode : null,
               SalesName: this.search.SalesName ? this.search.SalesName : null,
               Mobile: this.search.Mobile ? this.search.Mobile : null,
            }
         });


         console.log("downloadQrList", res);

         var blob = new Blob([
            new Int8Array(res.data.Content.data)
         ]);
         if (res.data.FileName) {
            var title = res.data.FileName;
            this.blobDownload(blob, title);
         }
      }
   }

};
</script>



<style lang="scss" scoped>
.search_row {
   background-color: #fff;
   height: 50px;
}
.row_css {
   margin: 10px;
   height: 48px;
}
</style>

