import axios from "axios";
// import { ElMessage } from 'element-plus'
import global from '@/global/global'

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
   baseURL:global.apiUrl,
   // 超时
   timeout: 30000
});
// request拦截器
service.interceptors.request.use(
   (config) => {
      config.headers["token"] = sessionStorage.getItem("token");
      return config;
   },
   (error) => {
      console.log("error", error);
   }
);

// 响应拦截器
service.interceptors.response.use(
   (res) => {
      return res.data;
   },
   (error) => {
      console.log("error", error);
   }
);

export default service;
