import request from '@/utils/request';


export function downloadQrList(data) {
    return request({
        url: "/openapi/BBRQRCodeOpService/download",
        method: "post",
        data: data
    })
}


export function getQrList(data) {
    return request({
        url: "/openapi/BBRQRCodeService/list",
        method: "post",
        data: data
    })
}

export function addQrCode(data) {
    return request({
        url: "/openapi/BBRQRCodeOpService/save",
        method: "post",
        data: data
    })
}


